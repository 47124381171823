import { g } from '@pretto/bricks/components/layout'

import React from 'react'
import styled from 'styled-components'

export type OptionType = { label: string; id: string }

export type OptionsListProps = {
  optionsList: OptionType[]
}

export const OptionsList: React.FC<OptionsListProps> = ({ optionsList, ...props }) => (
  <OptionsListContainer {...props}>
    {optionsList.map(({ label, id }) => (
      <Option key={label} href={`#${id}`}>
        {label}
      </Option>
    ))}
  </OptionsListContainer>
)

const OptionsListContainer = styled.ol`
  width: 100%;
`

const Option = styled.a.attrs({ forwardedAs: 'li' })`
  ${({ theme }) => theme.typos.caption};
  display: flex;
  width: 100%;

  & + & {
    margin-top: ${g(1, 4)};
  }

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary1};
  }
`
