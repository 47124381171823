import { ROW_CONTENT } from '@pretto/website/src/config/rowsNames'
import { slugify } from '@pretto/website/src/templates/blocs/lib/slugify'
import { OptionType } from '@pretto/website/src/templates/blocs/rows/rowSummary/components/OptionsList/OptionsList'
import { RowSummary } from '@pretto/website/src/templates/blocs/rows/rowSummary/components/RowSummary/RowSummary'
import { getMarkdownHeadings } from '@pretto/website/src/templates/blocs/rows/rowSummary/lib/getMarkdownHeadings'

type RowType = { __typename: string; markdownContent?: string; title?: string }

type RowSummaryContainerProps = {
  isColored?: boolean
  rows: RowType[]
}

export const RowSummaryContainer: React.FC<RowSummaryContainerProps> = ({ isColored, rows, ...props }) => {
  const sections: OptionType[] = rows.reduce((prev: OptionType[], row: RowType) => {
    if (row.__typename === ROW_CONTENT && row.markdownContent) {
      const rowSections = getMarkdownHeadings({ markdown: row.markdownContent, headingType: 'h2' })
      return [...prev, ...rowSections]
    }

    if (row.title) {
      return [...prev, { label: row.title, id: slugify(row.title) }]
    }

    return prev
  }, [])

  return <RowSummary isColored={isColored} optionsList={sections} {...props} />
}
