import { type OptionType } from '@pretto/website/src/templates/blocs/rows/rowSummary/components/OptionsList/OptionsList'

export const splitArray = (array: OptionType[], isMobile: boolean, maxElemBeforeSplit: number) => {
  if (array.length <= maxElemBeforeSplit || isMobile) {
    return [array, []]
  }

  const midpoint = Math.ceil(array.length / 2)
  const firstHalf = array.slice(0, midpoint)
  const secondHalf = array.slice(midpoint)

  return [firstHalf, secondHalf]
}
