import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'

import { NewBlocRow as NewBlocRowComponent } from '@pretto/website/src/templates/blocs/components/NewBlocRow'
import { anchorStyle } from '@pretto/website/src/templates/blocs/styles/anchorStyle'

import styled, { css } from 'styled-components'

import { OptionsList } from '../OptionsList/OptionsList'

export const ROW_SUMMARY_BG_COLORS = {
  light: 'white',
  colored: 'neutral4',
}

type IsDeployedProps = { $isSummaryOpened: boolean }

type OptionsListWidthProps = { $isFullWidth: boolean }

export const NewBlocRow = styled(NewBlocRowComponent)`
  ${grid()};
  ${({ theme }) => theme.typos.body4};
  background-color: ${({ isColored, theme }) =>
    theme.colors[isColored ? ROW_SUMMARY_BG_COLORS.colored : ROW_SUMMARY_BG_COLORS.light]};
`

export const TitleSection = styled.div`
  ${column([2, 4])};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${g(2)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral3};
  margin-bottom: ${g(2)};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.neutral1};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 2])};
    align-items: flex-start;
    padding-bottom: unset;
    border-bottom: unset;
    cursor: unset;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([3, 2])};
  }
`

export const Title = styled.h2`
  ${anchorStyle};
  ${({ theme }) => theme.typos.heading5};
`

export const DeployPicto = styled.div<IsDeployedProps>`
  display: block;
  margin-left: ${g(2)};
  height: ${g(2)};
  width: ${g(2)};
  transition: transform 0.3s ease-in-out;

  ${({ $isSummaryOpened }) =>
    $isSummaryOpened &&
    css`
      transform: rotate(180deg);
    `}

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: none;
  }
`

export const OptionsSection = styled.div`
  ${column([2, 4])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([4, 10])};
    padding-top: ${g(0, 4)};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([5, 8])};
  }
`

export const OptionsLists = styled.div`
  width: 100%;
  display: flex;
  gap: ${g(3)};
`

export const OptionsListLeft = styled(OptionsList)<OptionsListWidthProps>`
  width: 100%;

  ${({ $isFullWidth }) =>
    !$isFullWidth &&
    css`
      @media screen and (min-width: ${breakpoints.tablet}) {
        width: 50%;
      }
    `}
`

export const OptionsListRight = styled(OptionsList)`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: block;
    width: 50%;
  }
`
