import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'

import { useBreakpoint } from '@pretto/zen/reveal/lib/useBreakpoint'

import { ChevronDownBold } from '@pretto/picto'
import { type OptionType } from '@pretto/website/src/templates/blocs/rows/rowSummary/components/OptionsList/OptionsList'
import { splitArray } from '@pretto/website/src/templates/blocs/rows/rowSummary/lib/splitArray'

import { useEffect, useState } from 'react'

import * as S from './RowSummary.styles'

const MAX_ELEM_BEFORE_SPLIT = 4
const SUMMARY_TITLE = 'Sommaire'

export type RowSummaryProps = {
  optionsList: OptionType[]
  isColored?: boolean
}

export const RowSummary: React.FC<RowSummaryProps> = ({ isColored = false, optionsList, ...props }) => {
  const [isSummaryOpened, setIsSummaryOpened] = useState<boolean>(true)
  const [isMobileOrTablet, setIsMobileOrTablet] = useState<boolean>(false)
  const device = useBreakpoint()

  useEffect(() => {
    if (device) {
      setIsMobileOrTablet(!['desktop', 'laptop'].includes(device))
    }
  }, [device])

  const [optionsListLeft, optionsListRight] = splitArray(optionsList, isMobileOrTablet, MAX_ELEM_BEFORE_SPLIT)

  const handleDeploy = () => {
    if (isMobileOrTablet) {
      setIsSummaryOpened(prev => !prev)
    }
  }

  return (
    <S.NewBlocRow isColored={isColored} {...props}>
      <S.TitleSection
        as={isMobileOrTablet ? 'button' : 'div'}
        tabIndex={isMobileOrTablet ? 0 : -1}
        onClick={handleDeploy}
      >
        <S.Title>{SUMMARY_TITLE}</S.Title>
        <S.DeployPicto $isSummaryOpened={isSummaryOpened} as={ChevronDownBold} />
      </S.TitleSection>

      <S.OptionsSection>
        <CollapsibleRow animateIn in={isMobileOrTablet ? isSummaryOpened : true}>
          <S.OptionsLists>
            <S.OptionsListLeft optionsList={optionsListLeft} $isFullWidth={optionsListRight.length === 0} />

            {optionsListRight.length > 0 && <S.OptionsListRight optionsList={optionsListRight} />}
          </S.OptionsLists>
        </CollapsibleRow>
      </S.OptionsSection>
    </S.NewBlocRow>
  )
}
